import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import {
  useContentfulSection,
  useMeta,
  useContentfulComponent,
  useRichText,
  device,
} from "utils"

const PrivacyPolicyPage = ({ data: { pp } }) => {
  const { privacy_policy } = useContentfulSection(pp.sections)

  const { privacy_policy_doc } = useContentfulComponent(
    privacy_policy.components
  )

  const text = useRichText({ text: privacy_policy_doc.body })

  const meta = useMeta(pp)
  return (
    <Layout>
      <Seo {...meta} />
      <Nav />

      <Wrap>{text}</Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  padding: 0 var(--sp-32);
  padding-top: var(--sp-mobile-nav);
  max-width: 900px;
  margin: 0 auto;
  @media ${device.laptop} {
    padding-top: var(--sp-nav);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  li,
  a {
    /* white-space: break-spaces; */
    color: var(--primary-font-color);
  }

  h1 {
    margin-bottom: var(--sp-24);
  }

  h2,
  h3,
  h4 {
    margin-bottom: var(--sp-8);
  }
  p {
    white-space: break-spaces;
    margin-bottom: var(--sp-24);
  }

  ol,
  ul {
    padding-left: 18px;
    li {
      /* 18px */
      font-size: 1.125rem;
      line-height: 28px;

      @media ${device.laptop} {
        /* 20px */
        font-size: 1.25rem;
        line-height: 32px;
      }
      p {
        margin-bottom: 0;
      }
    }
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--sp-16);
    margin-bottom: var(--sp-24);
  }

  a {
    font-weight: 500;
    color: var(--spruce);
  }

  .gatsbyImageWrap {
    margin-bottom: var(--sp-24);
    img {
      object-fit: contain !important;
      /* object-position: left center; */
    }
  }
`

export const query = graphql`
  {
    pp: contentfulPage(pageId: { eq: "privacy_policy" }) {
      ...Page
    }
  }
`

export default PrivacyPolicyPage
